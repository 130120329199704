import React, { useEffect } from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import JoinGlassHiveCallout from '../../components/JoinGlassHiveCallout';
import { Container, Row, Col } from 'react-bootstrap';
import { Waypoint } from 'react-waypoint';
import './style.scss';

const IndexPage = () => {
    useEffect(() => {});

    return (
        <Layout navTheme="light">
            <Seo title="Branding Guidelines" />
            <div className="branding-guidelines-page">
                <Container className="hero-purple-gradient hero-container ">
                    <Row>
                        <Col className="centered-text hero-content">
                            <div className="hero-logo-div justify-center">
                                <img
                                    className="hero-logo"
                                    src={require('../../svgs/branding-guidelines/hero-logo.svg')}
                                    alt="glasshive logo"
                                />
                                <p className="hero-text white-text">
                                    Branding Guidelines
                                </p>
                            </div>
                        </Col>
                        <img
                            className="hero-pink-blob"
                            src={require('../../svgs/branding-guidelines/hero-pink-blob.svg')}
                            alt="pink blob"
                        />
                        <img
                            className="hero-green-circle"
                            src={require('../../svgs/branding-guidelines/hero-green-circle.svg')}
                            alt="green circle"
                        />
                        <img
                            className="hero-orange-blob"
                            src={require('../../svgs/branding-guidelines/hero-orange-blob.svg')}
                            alt="orange-blob"
                        />

                        <img
                            className="hero-purple-circle"
                            src={require('../../svgs/branding-guidelines/hero-purple-circle.svg')}
                            alt="purple-circle"
                        />
                        <img
                            className="hero-white-circle"
                            src={require('../../svgs/branding-guidelines/hero-white-circle.svg')}
                            alt="white-circle"
                        />
                        <img
                            className="hero-pink-circle"
                            src={require('../../svgs/branding-guidelines/hero-pink-circle.svg')}
                            alt="pink-circle"
                        />
                        <img
                            className="hero-blue-circle"
                            src={require('../../svgs/branding-guidelines/hero-blue-circle.svg')}
                            alt="blue-circle"
                        />
                        <img
                            className="hero-white-swoop"
                            src={require('../../images/branding-guidelines/hero-white-swoop.png')}
                            alt="white-swoop"
                        />
                    </Row>
                </Container>
                <Container className="hero-transparent-swoosh button-container ">
                    <Row className="">
                        <a class="menu-item" href="#logo">
                            <img
                                className="row-1-logo"
                                src={require('../../svgs/branding-guidelines/row-1-logo.svg')}
                                alt="logo"
                            />
                            <p className="centered-text">Logo</p>
                        </a>
                        <a class="menu-item" href="#pallets">
                            <img
                                className="row-1-pallets"
                                src={require('../../svgs/branding-guidelines/row-1-pallets.svg')}
                                alt="pallets"
                            />
                            <p className="centered-text">Pallets</p>
                        </a>
                        <a class="menu-item" href="#fonts">
                            <img
                                className="row-1-fonts"
                                src={require('../../svgs/branding-guidelines/row-1-fonts.svg')}
                                alt="fonts"
                            />
                            <p className="centered-text">Fonts</p>
                        </a>
                        <a class="menu-item" href="#social-media">
                            <img
                                className="row-1-social-media"
                                src={require('../../svgs/branding-guidelines/row-1-social-media.svg')}
                                alt="social-media"
                            />
                            <p className="centered-text">Social Media</p>
                        </a>
                        <a class="menu-item" href="#copy">
                            <img
                                className="row-1-copy"
                                src={require('../../svgs/branding-guidelines/row-1-copy.svg')}
                                alt="copy"
                            />
                            <p className="centered-text">Copy</p>
                        </a>
                        <a class="menu-item" href="#video">
                            <img
                                className="row-1-video"
                                src={require('../../svgs/branding-guidelines/row-1-video.svg')}
                                alt="video"
                            />
                            <p className="centered-text">Video</p>
                        </a>
                        <a class="menu-item" href="#illustration">
                            <img
                                className="row-1-illustration"
                                src={require('../../svgs/branding-guidelines/row-1-illustration.svg')}
                                alt="illustration"
                            />
                            <p className="centered-text">Illustration</p>
                        </a>
                        <a class="menu-item" href="#imagery">
                            <img
                                className="row-1-imagery"
                                src={require('../../svgs/branding-guidelines/row-1-imagery.svg')}
                                alt="imagery"
                            />
                            <p className="centered-text">Imagery</p>
                        </a>
                        <a class="menu-item" href="#icons">
                            <img
                                className="row-1-icons"
                                src={require('../../svgs/branding-guidelines/row-1-icons.svg')}
                                alt="icons"
                            />
                            <p className="centered-text">Icons</p>
                        </a>
                        <a class="menu-item" href="#in-actions">
                            <img
                                className="row-1-in-action"
                                src={require('../../svgs/branding-guidelines/row-1-in-action.svg')}
                                alt="in-action"
                            />
                            <p className="centered-text">In-Actions</p>
                        </a>
                    </Row>
                </Container>
                <Container
                    id="logo"
                    className="grey-swish-background logo-container"
                >
                    <Row className="row-3">
                        <Col className="one-half  row-3-grey-circle">
                            <h3 className="centered-mobile">Our Logo</h3>
                            <p className="wrapLineSib centered-mobile">
                                The GlassHive logo is composed of an hexagon
                                that represent the hive.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                The GlassHive logo is composed of an hexagon
                                that represent the hive. The gradient color on a
                                G represent the variarity of GlassHive as
                                company external and internally.
                            </p>
                        </Col>
                        <Col className="one-half centered-text rel second-col width-55-perc">
                            <img
                                className="gl-group-logo"
                                src={require('../../images/branding-guidelines/gl-group-logo.png')}
                                alt=""
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="background-container">
                    <Row className="usage-row ">
                        <Col className="one-half  large-backgrounds ">
                            <h2 className="centered-mobile">
                                Useage on backgrounds
                            </h2>
                            <div className="logo-backgrounds white">
                                <img
                                    className="row-4-purple-logo"
                                    src={require('../../svgs/branding-guidelines/row-4-purple-logo.svg')}
                                    alt=""
                                />
                            </div>
                            <div className="logo-backgrounds black">
                                <img
                                    className="row-4-white-logo-1"
                                    src={require('../../svgs/branding-guidelines/row-4-white-logo-1.svg')}
                                    alt=""
                                />
                            </div>
                            <div className="logo-backgrounds purple">
                                <img
                                    className="row-4-white-logo-1 "
                                    src={require('../../svgs/branding-guidelines/row-4-white-logo-1.svg')}
                                    alt=""
                                />
                            </div>
                            <p className="wrapLineSib centered-mobile">
                                The full-color logo must only be used on white,
                                black, or any solid color.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                We do not recommend using full-color logos on
                                photographs unless the logo is placed on a black
                                or white area of the image.
                            </p>
                        </Col>
                        <Col className=" one-half ">
                            <h2 className="centered-mobile">One-color use</h2>
                            <div class="small-backgrounds">
                                <div className="background-column">
                                    <div className="mini-background city">
                                        <img
                                            className="row-4-mini-white-logo-1"
                                            src={require('../../svgs/branding-guidelines/row-4-mini-white-logo-1.svg')}
                                            alt=""
                                        />
                                    </div>
                                    <div className="mini-background blue">
                                        <img
                                            className="row-4-mini-white-logo-2"
                                            src={require('../../svgs/branding-guidelines/row-4-mini-white-logo-1.svg')}
                                            alt=""
                                        />
                                    </div>
                                    <div class="mini-background pink">
                                        <img
                                            className="row-4-mini-white-logo-3"
                                            src={require('../../svgs/branding-guidelines/row-4-mini-white-logo-3.svg')}
                                            alt=""
                                        />
                                    </div>
                                </div>

                                <div className="background-column">
                                    <div class="mini-background purple">
                                        <img
                                            className="row-4-mini-white-logo-4"
                                            src={require('../../svgs/branding-guidelines/row-4-mini-white-logo-4.svg')}
                                            alt=""
                                        />
                                    </div>
                                    <div class="mini-background orange">
                                        <img
                                            className="row-4-mini-white-logo-5"
                                            src={require('../../svgs/branding-guidelines/row-4-mini-white-logo-5.svg')}
                                            alt=""
                                        />
                                    </div>
                                    <div class="mini-background grey">
                                        <img
                                            className="row-4-mini-purple-logo"
                                            src={require('../../svgs/branding-guidelines/row-4-mini-purple-logo.svg')}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <p className="wrapLineSib centered-mobile">
                                The one-color logo must only be used on
                                photographs and colored backgrounds within the
                                GlassHive color palette.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container
                    id="pallets"
                    className="color-palette-container row-5-bottom-wave-1 row-5-bottom-wave-2 row-5-paint-drops  "
                >
                    <Row>
                        <Col>
                            <h3 className=" white-text">Color Palette</h3>
                        </Col>
                    </Row>
                </Container>
                <Container className="solid-container">
                    <Row>
                        <Col className="one-half ">
                            <h2 className="centered-mobile">Solids</h2>
                            <p className="wrapLineSib centered-mobile">
                                These are the five primary colors of GlassHive.
                                They can be used for content such as body,
                                headings, and text quotes. Additionally, they
                                can be used on icons and small objects.
                            </p>
                        </Col>
                        <Col className="one-half ">
                            <div class="flexed">
                                <div class="color-section pink">
                                    <div class="circles">
                                        <div class="large-circle circle"></div>
                                        <div class="small-circles">
                                            <div class="small-circle eighty circle "></div>
                                            <div class="small-circle sixty circle"></div>
                                            <div class="small-circle fourty circle"></div>
                                            <div class="small-circle twenty circle"></div>
                                        </div>
                                    </div>
                                    <p>
                                        <span class="heavy-weight">PMS:</span>
                                        213 C
                                    </p>
                                    <p>
                                        <span class="heavy-weight">
                                            C CMYK:
                                        </span>
                                        0 - 100 - 24 - 0
                                    </p>
                                    <p>
                                        <span class="heavy-weight">RGB:</span>
                                        243 - 0 - 112
                                    </p>
                                    <p>
                                        <span class="heavy-weight">HEX:</span>
                                        #F30070
                                    </p>
                                </div>
                                <div class="color-section purple">
                                    <div class="circles">
                                        <div class="large-circle circle"></div>
                                        <div class="small-circles">
                                            <div class="small-circle eighty circle "></div>
                                            <div class="small-circle sixty circle"></div>
                                            <div class="small-circle fourty circle"></div>
                                            <div class="small-circle twenty circle"></div>
                                        </div>
                                    </div>
                                    <p>
                                        <span class="heavy-weight">PMS:</span>
                                        2725 C
                                    </p>
                                    <p>
                                        <span class="heavy-weight">
                                            C CMYK:
                                        </span>
                                        65 - 69 - 0 - 0
                                    </p>
                                    <p>
                                        <span class="heavy-weight">RGB:</span>
                                        126 - 93 - 255
                                    </p>
                                    <p>
                                        <span class="heavy-weight">HEX:</span>
                                        #7E5DFF
                                    </p>
                                </div>
                                <div class="color-section blue">
                                    <div class="circles">
                                        <div class="large-circle circle"></div>
                                        <div class="small-circles">
                                            <div class="small-circle eighty circle "></div>
                                            <div class="small-circle sixty circle"></div>
                                            <div class="small-circle fourty circle"></div>
                                            <div class="small-circle twenty circle"></div>
                                        </div>
                                    </div>
                                    <p>
                                        <span class="heavy-weight">PMS:</span>
                                        s995 C
                                    </p>
                                    <p>
                                        <span class="heavy-weight">
                                            C CMYK:
                                        </span>
                                        75 - 15 - 0 - 0
                                    </p>
                                    <p>
                                        <span class="heavy-weight">RGB:</span>
                                        24 - 174 - 255
                                    </p>
                                    <p>
                                        <span class="heavy-weight">HEX:</span>
                                        #18AEFF
                                    </p>
                                </div>
                            </div>
                            <div class="flexed">
                                <div class="color-section violet">
                                    <div class="circles">
                                        <div class="large-circle circle"></div>
                                        <div class="small-circles">
                                            <div class="small-circle eighty circle "></div>
                                            <div class="small-circle sixty circle"></div>
                                            <div class="small-circle fourty circle"></div>
                                            <div class="small-circle twenty circle"></div>
                                        </div>
                                    </div>
                                    <p>
                                        <span class="heavy-weight">PMS:</span>
                                        669 C
                                    </p>
                                    <p>
                                        <span class="heavy-weight">
                                            C CMYK:
                                        </span>
                                        87 - 100 - 34 - 30
                                    </p>
                                    <p>
                                        <span class="heavy-weight">RGB:</span>
                                        56 - 33 - 83
                                    </p>
                                    <p>
                                        <span class="heavy-weight">HEX:</span>
                                        #382153
                                    </p>
                                </div>
                                <div class="color-section grey">
                                    <div class="circles">
                                        <div class="large-circle circle"></div>
                                        <div class="small-circles">
                                            <div class="small-circle eighty circle "></div>
                                            <div class="small-circle sixty circle"></div>
                                            <div class="small-circle fourty circle"></div>
                                            <div class="small-circle twenty circle"></div>
                                        </div>
                                    </div>
                                    <p>
                                        <span class="heavy-weight">PMS:</span>
                                        663 C
                                    </p>
                                    <p>
                                        <span class="heavy-weight">
                                            C CMYK:
                                        </span>
                                        4 - 4 - 1 - 0
                                    </p>
                                    <p>
                                        <span class="heavy-weight">RGB:</span>
                                        239 - 239 - 244
                                    </p>
                                    <p>
                                        <span class="heavy-weight">HEX:</span>
                                        #EFEFF4
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className=" gradient-container">
                    <Row>
                        <Col className="one-third ">
                            <h2 className="centered-mobile">Gradients</h2>
                            <p className="wrapLineSib centered-mobile">
                                Gradients will enhance the branding of
                                GlassHive. The use of gradients is specifically
                                used for digital content, however, can be used
                                for print as needed. The gradients can be
                                applied to shapes, icons, and at times,
                                headings.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                In regards to social media and the website, they
                                can be used as background sections where the
                                primary color for the body and headings is
                                white.
                            </p>
                        </Col>
                        <Col className="two-thirds ">
                            <div class="flexed">
                                <div class="color-section pink">
                                    <div class="circles">
                                        <div class="large-circle circle"></div>
                                        <div class="small-circles">
                                            <div class="small-circle eighty circle "></div>
                                            <div class="small-circle sixty circle"></div>
                                            <div class="small-circle fourty circle"></div>
                                            <div class="small-circle twenty circle"></div>
                                        </div>
                                    </div>
                                    <p>
                                        <span class="heavy-weight">HEX: </span>
                                        #F30070
                                    </p>
                                    <p>
                                        <span class="heavy-weight">HEX: </span>
                                        #FF899D
                                    </p>
                                </div>
                                <div class="color-section purple">
                                    <div class="circles">
                                        <div class="large-circle circle"></div>
                                        <div class="small-circles">
                                            <div class="small-circle eighty circle "></div>
                                            <div class="small-circle sixty circle"></div>
                                            <div class="small-circle fourty circle"></div>
                                            <div class="small-circle twenty circle"></div>
                                        </div>
                                    </div>
                                    <p>
                                        <span class="heavy-weight">HEX: </span>
                                        #7A5BFF
                                    </p>
                                    <p>
                                        <span class="heavy-weight">HEX: </span>
                                        #E38DEE
                                    </p>
                                </div>
                                <div class="color-section blue">
                                    <div class="circles">
                                        <div class="large-circle circle"></div>
                                        <div class="small-circles">
                                            <div class="small-circle eighty circle "></div>
                                            <div class="small-circle sixty circle"></div>
                                            <div class="small-circle fourty circle"></div>
                                            <div class="small-circle twenty circle"></div>
                                        </div>
                                    </div>
                                    <p>
                                        <span class="heavy-weight">HEX: </span>
                                        #5052FE
                                    </p>
                                    <p>
                                        <span class="heavy-weight">HEX: </span>
                                        #4BC9FE
                                    </p>
                                </div>
                                <div class="color-section peach">
                                    <div class="circles">
                                        <div class="large-circle circle"></div>
                                        <div class="small-circles">
                                            <div class="small-circle eighty circle "></div>
                                            <div class="small-circle sixty circle"></div>
                                            <div class="small-circle fourty circle"></div>
                                            <div class="small-circle twenty circle"></div>
                                        </div>
                                    </div>
                                    <p>
                                        <span class="heavy-weight">HEX: </span>
                                        #FE6370
                                    </p>
                                    <p>
                                        <span class="heavy-weight">HEX: </span>
                                        #F3C27E
                                    </p>
                                </div>
                            </div>
                            <div class="flexed">
                                <div class="color-section green">
                                    <div class="circles">
                                        <div class="large-circle circle"></div>
                                        <div class="small-circles">
                                            <div class="small-circle eighty circle "></div>
                                            <div class="small-circle sixty circle"></div>
                                            <div class="small-circle fourty circle"></div>
                                            <div class="small-circle twenty circle"></div>
                                        </div>
                                    </div>
                                    <p>
                                        <span class="heavy-weight">HEX: </span>
                                        #28DA34
                                    </p>
                                    <p>
                                        <span class="heavy-weight">HEX: </span>
                                        #13E9E7
                                    </p>
                                </div>
                                <div class="color-section orange">
                                    <div class="circles">
                                        <div class="large-circle circle"></div>
                                        <div class="small-circles">
                                            <div class="small-circle eighty circle "></div>
                                            <div class="small-circle sixty circle"></div>
                                            <div class="small-circle fourty circle"></div>
                                            <div class="small-circle twenty circle"></div>
                                        </div>
                                    </div>
                                    <p>
                                        <span class="heavy-weight">HEX: </span>
                                        #F9D423
                                    </p>
                                    <p>
                                        <span class="heavy-weight">HEX: </span>
                                        #EB7E0A
                                    </p>
                                </div>
                                <div class="color-section cyan">
                                    <div class="circles">
                                        <div class="large-circle circle"></div>
                                        <div class="small-circles">
                                            <div class="small-circle eighty circle "></div>
                                            <div class="small-circle sixty circle"></div>
                                            <div class="small-circle fourty circle"></div>
                                            <div class="small-circle twenty circle"></div>
                                        </div>
                                    </div>
                                    <p>
                                        <span class="heavy-weight">HEX: </span>
                                        #18AEFF
                                    </p>
                                    <p>
                                        <span class="heavy-weight">HEX: </span>
                                        #80F0D6
                                    </p>
                                </div>
                                <div class="color-section violet">
                                    <div class="circles">
                                        <div class="large-circle circle"></div>
                                        <div class="small-circles">
                                            <div class="small-circle eighty circle "></div>
                                            <div class="small-circle sixty circle"></div>
                                            <div class="small-circle fourty circle"></div>
                                            <div class="small-circle twenty circle"></div>
                                        </div>
                                    </div>
                                    <p>
                                        <span class="heavy-weight">HEX: </span>
                                        #5718A5
                                    </p>
                                    <p>
                                        <span class="heavy-weight">HEX: </span>
                                        #2D2437
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container
                    id="fonts"
                    className="font-container row-6-pink-background"
                >
                    <Row className="nunito-row">
                        <Col className="one-half ">
                            <h3 className="centered-mobile white-text">
                                Fonts
                            </h3>
                            <p className="centered-mobile white-text heading-subtext">
                                Headings
                            </p>
                            <p className="centered-mobile  purple-subtext">
                                Nunito
                            </p>
                            <p className="wrapLineSib centered-mobile white-text nunito-p">
                                Nunito is a well balanced sans serif typeface
                                superfamily, with 2 versions: The project began
                                with Nunito, created by Vernon Adams as a
                                rounded terminal sans serif for display
                                typography. Jacques Le Bailly extended it to a
                                full set of weights, and an accompanying regular
                                non-rounded terminal version, Nunito Sans.
                            </p>
                            <div className="nunito-fonts">
                                <p className="wrapLineSib centered-mobile white-text nunito-text-size heavy-weight">
                                    ExtraBold
                                </p>
                                <p className="wrapLineSib centered-mobile white-text nunito-text-size welter-weight">
                                    Medium
                                </p>
                                <p className="wrapLineSib centered-mobile white-text nunito-text-size light-weight">
                                    Light
                                </p>
                            </div>
                        </Col>
                        <Col className="one-half ">
                            <div>
                                <p className=" wallpaper-text white-text">
                                    A
                                    <p className=" wallpaper-text-lower white-text light-weight">
                                        a
                                    </p>
                                </p>
                            </div>
                            <div>
                                <p className="wrapLineSib centered-mobile white-text nunito-p2 light-weight right-font-column">
                                    A B C D E F G H I J K L M N O P Q R S T U V
                                    W X Y Z a b c d e f g h i j k l m n o p q r
                                    s t u v w x y z 1 2 3 4 5 6 7 8 9 0 ! @ # $
                                    % ^ & * ( ) _ + =
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="one-half roboto-col">
                            <p className="centered-mobile white-text heading-subtext">
                                Body Text Only
                            </p>
                            <p className="centered-mobile  purple-subtext">
                                Roboto
                            </p>
                            <p className="wrapLineSib centered-mobile white-text roboto-p">
                                Roboto has a dual nature. It has a mechanical
                                skeleton and the forms are largely geometric. At
                                the same time, the font features friendly and
                                open curves. While some grotesks distort their
                                letterforms to force a rigid rhythm, Roboto
                                doesn’t compromise, allowing letters to be
                                settled into their natural width. This makes for
                                a more natural reading rhythm more commonly
                                found in humanist and serif types.
                            </p>
                            <div className="nunito-fonts">
                                <p className="wrapLineSib centered-mobile white-text roboto-text-size heavy-weight">
                                    Bold
                                </p>
                                <p className="wrapLineSib centered-mobile white-text roboto-text-size welter-weight">
                                    SemiBold
                                </p>
                                <p className="wrapLineSib centered-mobile white-text roboto-text-size light-weight">
                                    Light
                                </p>
                            </div>
                        </Col>
                        <Col className="one-half ">
                            <div>
                                <p className="wrapLineSib centered-mobile white-text roboto-p2 light-weight second-right-font-column">
                                    A B C D E F G H I J K L M N O P Q R S T U V
                                    W X Y Z a b c d e f g h i j k l m n o p q r
                                    s t u v w x y z 1 2 3 4 5 6 7 8 9 0 ! @ # $
                                    % ^ & * ( ) _ + =
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container
                    id="social-media"
                    className="social-media-container magenta-swoop row-7-purple-gradient-background"
                >
                    <Row>
                        <Col className="one-half max-width-542">
                            <h3 className="centered-mobile white-text">
                                Social Media
                            </h3>
                            <p className="wrapLineSib centered-mobile white-text nunito-p">
                                The idea behind social media ads and marketing
                                design is to create a fun, beautiful piece of
                                art based on the demographic of the social
                                platform. That includes packages as simple,
                                heavy elements, and in between. Each platform
                                has some unique aspects of design. Please take a
                                look below for some samples for each platform.
                            </p>
                        </Col>
                    </Row>
                    <Row className="label-row">
                        <Col>
                            <p className="centered-mobile white-text heading-subtext">
                                LinkedIn
                            </p>
                        </Col>
                    </Row>
                    <Row className="social-media">
                        <Col className="one-half ">
                            <img
                                className="linkedin-post-1"
                                src={require('../../images/branding-guidelines/LinkedIn-Post–1.png')}
                                alt=""
                            />
                            <img
                                className="linkedin-post-3"
                                src={require('../../images/branding-guidelines/LinkedIn-Post–3.png')}
                                alt=""
                            />
                        </Col>
                        <Col className=" one-half ">
                            <img
                                className="linkedin-post-2"
                                src={require('../../images/branding-guidelines/LinkedIn-Post-2.png')}
                                alt=""
                            />
                        </Col>
                    </Row>
                    <Row className="label-row">
                        <Col>
                            <p className="centered-mobile white-text heading-subtext">
                                Facebook
                            </p>
                        </Col>
                    </Row>
                    <Row className="social-media">
                        <Col className=" ">
                            <img
                                className="fb-feed-1"
                                src={require('../../images/branding-guidelines/fb-feed-1.png')}
                                alt=""
                            />
                            <p className="centered-mobile white-text social-media-text">
                                Simplicity
                            </p>
                        </Col>
                        <Col className="">
                            <img
                                className="fb-feed-2"
                                src={require('../../images/branding-guidelines/fb-feed-2.png')}
                                alt=""
                            />
                            <p className="centered-mobile white-text social-media-text">
                                In between
                            </p>
                        </Col>
                        <Col className="  ">
                            <img
                                className="fb-feed-3"
                                src={require('../../images/branding-guidelines/fb-feed-3.png')}
                                alt=""
                            />
                            <p className="centered-mobile white-text social-media-text">
                                Lot of elements
                            </p>
                        </Col>
                    </Row>
                    <Row className="label-row">
                        <Col>
                            <p className="centered-mobile white-text heading-subtext">
                                Instagram
                            </p>
                        </Col>
                    </Row>
                    <Row className="social-media">
                        <Col className=" ">
                            <img
                                className="ig-feed-9"
                                src={require('../../images/branding-guidelines/ig-feed-9.png')}
                                alt=""
                            />
                            <img
                                className="ig-feed-12"
                                src={require('../../images/branding-guidelines/ig-feed-12.png')}
                                alt=""
                            />
                            <p className="centered-mobile white-text social-media-text">
                                Simplicity
                            </p>
                        </Col>
                        <Col className=" ig-col-2">
                            <img
                                className="ig-feed-10"
                                src={require('../../images/branding-guidelines/ig-feed-10.png')}
                                alt=""
                            />
                            <img
                                className="ig-feed-13"
                                src={require('../../images/branding-guidelines/ig-feed-13.png')}
                                alt=""
                            />
                            <p className="centered-mobile white-text social-media-text">
                                In between
                            </p>
                        </Col>
                        <Col className=" ig-col-2">
                            <img
                                className="ig-feed-11"
                                src={require('../../images/branding-guidelines/ig-feed-11.png')}
                                alt=""
                            />
                            <img
                                className="ig-feed-14"
                                src={require('../../images/branding-guidelines/ig-feed-14.png')}
                                alt=""
                            />
                            <p className="centered-mobile white-text social-media-text">
                                Lot of elements
                            </p>
                        </Col>
                    </Row>
                    <Row className="title-row">
                        <p className="centered-mobile slide-title white-text contered-text heading-subtext">
                            10 Post Slide
                        </p>
                    </Row>
                    <Row className="slide-row">
                        <img
                            className="slide-1"
                            src={require('../../images/branding-guidelines/slide-1.png')}
                            alt=""
                        />
                    </Row>
                    <Row className="slide-row">
                        <img
                            className="slide-2"
                            src={require('../../images/branding-guidelines/slide-2.png')}
                            alt=""
                        />
                    </Row>
                    <Row className="slide-row">
                        <img
                            className="slide-3"
                            src={require('../../images/branding-guidelines/slide-3.png')}
                            alt=""
                        />
                    </Row>
                </Container>
                <Container id="copy" class="content" className="tone-container">
                    <Row>
                        <Col>
                            <h2>Content Tone & Voice</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="one-third theme-column">
                            <h3>Theme</h3>
                            <p>
                                Our tagline and motto is{' '}
                                <span class="heavy-weight">
                                    “Making sales and marketing simple and fun”
                                </span>
                            </p>
                            <p>
                                GlassHive is innovative. We’ve pushed the bounds
                                of a typical software platform and CRM and
                                created a platform that consolidates all your
                                sales and marketing tools into one unified
                                workOS. Our copy should constantly promote that
                                spirit and highlight the features of the
                                platform in a manner that is digestible,
                                conversational, yet informative.
                            </p>
                        </Col>
                        <Col className="one-third theme-column">
                            <h3>Approach</h3>
                            <p>
                                Our content should be a resource to our audience
                                to educate and help them improve their marketing
                                and sales processes. The content should be
                                conversational, intentional, and fun. Nobody
                                likes reading boring technical jargon. It should
                                be engaging and the amount of professional fluff
                                should be limited. Remember, always make things
                                simple and fun…even copy!
                            </p>
                        </Col>
                        <Col className="one-third theme-column">
                            <h3>Target Audience</h3>
                            <p>
                                IT companies, MSPs, and vendors that need an
                                all-in-one solution for sales and marketing
                                activities. Companies that are seeking the tools
                                to overcome sales challenges, start or expand
                                upon their marketing efforts, and simplify their
                                workflows, all on one platform.
                            </p>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="border-column">
                            <div class="border"></div>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="one-half">
                            <h3>Our Voice:</h3>
                            <p>
                                When writing for GlassHive, our voice is always:
                            </p>
                            <ul class="check">
                                <li>
                                    Simple and to the point: clear and concise
                                    so not to lose the point
                                </li>
                                <li>
                                    Fun and whimsical: we want to engage our
                                    audience with relevant content
                                </li>
                                <li>
                                    Sincere, like talking to a friend: be
                                    honest, don’t use false information, down to
                                    earth conversations
                                </li>
                                <li>
                                    Action driven and motivating: action drives
                                    motivation! Use strong CTA and power words
                                    to motivate audience to take action and seek
                                    knowledge from GlassHive
                                </li>
                                <li>
                                    Storytelling backed up by data: use of
                                    testimonials and case studies whenever
                                    possible. Stories from peers within the
                                    industry are gold!
                                </li>
                            </ul>

                            <div class="purple-bubble">
                                <h3 class="white-text">
                                    Tips for Helpful content
                                </h3>
                                <ul class="check">
                                    <li class="white-text">
                                        Whenever possible use credible sources
                                        with links and relevant articles.
                                    </li>
                                    <li class="white-text">
                                        Insert a downloadable resource to
                                        support copy message and research.{' '}
                                    </li>
                                    <li class="white-text">
                                        Focus on providing value through content
                                        for our audience.{' '}
                                    </li>
                                    <li class="white-text">
                                        Use relevant, insightful data to bring
                                        the point home and ensure helpfulness
                                    </li>
                                    <li class="white-text">
                                        Focus on helping and providing value for
                                        our customers in the content, we want to
                                        be a resource for open communication,
                                        don’t overuse our success stories.
                                    </li>
                                </ul>
                            </div>
                        </Col>

                        <Col className="one-half">
                            <h3>Our Tone:</h3>
                            <p>
                                GlassHive’s tone is conversional yet
                                informative. All copy to be used for marketing
                                material must adhere to the following universal
                                guidelines:{' '}
                            </p>
                            <ul class="check">
                                <li>
                                    90% of copy should be helpful (see below)
                                </li>
                                <li>
                                    Copy should be in an active tone, not
                                    passive to build and encourage active
                                    learning
                                </li>
                                <li>
                                    Copy should be informative and simple enough
                                    for a 7th grade or lower to read
                                </li>
                                <li>
                                    Our aim is to help and be a resource. Copy
                                    should assist and give our readers a can-do
                                    attitude
                                </li>
                                <li>
                                    Copy should be inviting and communicative to
                                    encourage readers and build knowledge while
                                    positioning ourselves as marketing experts
                                </li>
                                <li>
                                    Copy should use concise language to be
                                    straightforward and impartial.
                                </li>
                                <li>
                                    Use power words (words that trigger an
                                    emotional and psychological response) to
                                    relate and induce reader\’s curiosity to
                                    \‘find out more\’ through GlassHive
                                </li>
                                <li>
                                    Copy should be relatable to the audience and
                                    not sound condescending or arrogant
                                </li>
                                <li>
                                    Copy should convey that we work in a
                                    studio-like environment and that our content
                                    is a behind the scenes peek at how the
                                    experts do marketing and sales
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
                <Container
                    id="grammer"
                    class="content"
                    className="grammer-container grammer-swoop"
                >
                    <Row className="grammatical-row">
                        <Col className="grammer-column">
                            <h3>Grammatical guidelines</h3>
                            <ul class="check">
                                <li>AP style</li>
                                <li>
                                    All titles and headers should be written in
                                    sentence casing
                                </li>
                                <li>
                                    Copy should be written in the first person
                                    plural
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className="do-dont-row">
                        <Col className="dos one-half half-bubble">
                            <img
                                class="circle-image"
                                src={require('../../images/branding-guidelines/do.png')}
                                alt=""
                            />
                            <h3>Do...</h3>
                            <ul class="check">
                                <li>
                                    Focus on correct grammar, punctuation and
                                    spelling
                                </li>
                                <li>Sound conversational yet informative</li>
                                <li>Convey understanding of readers\’ needs</li>
                                <li>
                                    Be descriptive, but not overly technical
                                </li>

                                <li>Get to the point quickly</li>
                            </ul>
                        </Col>
                        <Col className="donts one-half half-bubble">
                            <img
                                class="circle-image"
                                src={require('../../images/branding-guidelines/dont.png')}
                                alt=""
                            />
                            <h3>Don't...</h3>
                            <ul class="ex">
                                <li>Sound arrogant or demeaning</li>
                                <li>
                                    Use casual language that could discredit
                                    GlassHive in the eyes of business leaders
                                </li>
                                <li>Ramble </li>
                                <li>Use unfamiliar industry jargon</li>
                                <li>Use “filler” content</li>
                            </ul>
                        </Col>
                    </Row>

                    <Row className="sample-row">
                        <Col>
                            <h3 class="centered">
                                Sample of GlassHive’s tone:
                            </h3>
                            <h4> GlassHive overview video script</h4>
                            <p>Meet GlassHive </p>
                            <p>
                                It’s an app built for the IT industry that makes
                                sales and marketing simple and fun.
                            </p>
                            <p>
                                Using GlassHive’s unique automation and AI
                                features, thousands of IT businesses across 29
                                countries are marketing, generating leads and
                                making sales.
                            </p>
                            <p>
                                Whether you’re a small or enterprise business,
                                GlassHive has everything that your marketing and
                                sales team needs.
                            </p>
                            <p>
                                From robust Email, landing pages, and content
                                creation builders, to automations like
                                Journey’s, and Marketing Plans, your team will
                                have all the magic they need to be successful.
                            </p>
                            <p>
                                GlassHive’s AI automatically researches your
                                prospects for you, without the scary machine
                                uprising. From there, you can Automate sales
                                follow ups by adding prospects into a custom
                                journey.
                            </p>
                            <p>
                                Now let’s say you’re not a marketing guru… or
                                maybe you are. In any case, GlassHive comes with
                                a huge library of IT marketing content that
                                automatically changes to your company’s logo and
                                colors. So you can start generating leads in
                                minutes.
                            </p>
                            <p>
                                Wanna add more personality to your marketing? No
                                problem. All of our content is easily
                                customizable with our simple to use drag and
                                drop builders. You can then schedule months’
                                worth of marketing content in just a few clicks.
                            </p>
                            <p>
                                GlassHive is also your sales and marketing data
                                hub. Data that ranges from big-picture marketing
                                efforts down to individual sales reports or
                                contact activity.
                            </p>
                            <p>
                                And best of all, GlassHive automates data entry
                                by integrating with the tools you use most. So
                                you can finally stop preparing epic and
                                motivational speeches just to get your sales
                                team to enter data. Instead, be confident
                                knowing all your team’s activities will be
                                tracked on or off the platform.
                            </p>
                            <p>
                                With easy to access data and mind-blowing
                                reports you get the insight you need to make the
                                right decisions.
                            </p>
                            <p>
                                And if you’re not sure what to do, let GlassHive
                                show you with our industry benchmarks to help
                                you stay ahead of the sales and marketing game.
                            </p>
                            <p>
                                Come see the magic of GlassHive for yourself.
                                Start your 14-day free trial with access to
                                everything – no limitations. Afterwards, stay
                                for only $15 a user per month and consolidate
                                all your sales and marketing tools into one
                                unified workOS. Like we said…it’s simple & fun!
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container id="video" className="video-container">
                    <img
                        className="video-white-heading"
                        src={require('../../svgs/branding-guidelines/video-white-heading.svg')}
                        alt=""
                    />
                    <img
                        className="video-header"
                        src={require('../../svgs/branding-guidelines/video-header.svg')}
                        alt=""
                    />

                    <Row>
                        <Col className="one-half">
                            <h2>Video</h2>
                            <p>
                                It is very essential for our brand to be
                                identified in multiple outlets — video being one
                                of the more important ones. From GlassHive
                                tutorials to client testimonials, we need to
                                keep the branding bright and always shining.
                                Colors, fonts, and animations are the key to
                                spicing it up in videos.
                            </p>
                        </Col>
                    </Row>
                    <Row className="background-design-row">
                        <Col className="one-half">
                            <img
                                src={require('../../images/branding-guidelines/giovanni.jpg')}
                                alt=""
                            />
                            <img
                                src={require('../../images/branding-guidelines/journeys.jpg')}
                                alt=""
                            />
                        </Col>
                        <Col className="one-half">
                            <img
                                src={require('../../images/branding-guidelines/personal-follow-up.jpg')}
                                alt=""
                            />
                            <div class="content">
                                <h3>Set and Background Design</h3>
                                <p>
                                    We can go from a solid color to a set design
                                    for the background. Proper lighting and
                                    scenario are essential to keep videos
                                    similar and consistent with our branding.
                                </p>
                                <p>
                                    We use blue, pink, and yellow as the primary
                                    backdrop colors and add unique decor to
                                    decorate the shooting frame. A friendly
                                    smile and a touch of charisma are more than
                                    welcome.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="testimonial-row">
                        <Col className="one-half">
                            <div class="content">
                                <h3>Video Testimonial</h3>
                                <p>
                                    When a client comes to our office or we go
                                    to theirs, we need to make sure that somehow
                                    our branding will collide with their office,
                                    so, lighting, video editing is key to make
                                    sure each video falls in our guidelines for
                                    this type of recordings.
                                </p>
                            </div>
                            <img
                                src={require('../../images/branding-guidelines/testimonial-3.jpg')}
                                alt=""
                            />
                        </Col>
                        <Col className="one-half">
                            <img
                                src={require('../../images/branding-guidelines/testimonial-1.jpg')}
                                alt=""
                            />
                            <img
                                src={require('../../images/branding-guidelines/testimonial-2.jpg')}
                                alt=""
                            />
                        </Col>
                    </Row>
                    <Row className="animation-row">
                        <Col className="one-half">
                            <img
                                src={require('../../images/branding-guidelines/glasshive-video-animation.jpg')}
                                alt=""
                            />
                            <img
                                src={require('../../images/branding-guidelines/ai-video-animation.jpg')}
                                alt=""
                            />
                        </Col>
                        <Col className="one-half video-animation-column">
                            <div class="content">
                                <h3>Video Animation</h3>
                                <p>
                                    Our video animations are like a package of
                                    skittles – full of colors! And color is one
                                    of our fortes at GlassHive. Our video
                                    animations are created to be visually
                                    appealing to the viewer’s eyes.
                                </p>
                                <p>
                                    These videos are not limited to just the
                                    GlassHive’s website - they can be used on
                                    other platforms such as Facebook, Instagram,
                                    TikTok, and any other social media outlet
                                    where videos make a strong presence.
                                </p>
                            </div>
                            <img
                                src={require('../../images/branding-guidelines/dashboard-video-animation.jpg')}
                                alt=""
                            />
                        </Col>
                    </Row>
                </Container>
                <Container id="illustration" className="illustration-container">
                    <Row className="illustration-row">
                        <Col className="two-thirds">
                            <h2>Illustration</h2>
                            <p class="white-text">
                                We love illustrations, especially ones that help
                                bring the message to life. That’s why we decided
                                to go with 3D illustrations. We use Shutterstock
                                when necessary and tweak the asset to fit our
                                branding.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>

                    <Row className="illustration-images">
                        <Col className="flexed">
                            <img
                                src={require('../../images/branding-guidelines/bulb-illustration.png')}
                                alt=""
                            />
                            <img
                                src={require('../../images/branding-guidelines/email-illustration.png')}
                                alt=""
                            />
                            <img
                                src={require('../../images/branding-guidelines/report-illustration.png')}
                                alt=""
                            />
                            <img
                                src={require('../../images/branding-guidelines/book-illustration.png')}
                                alt=""
                            />
                        </Col>
                    </Row>

                    <Row>
                        <p class="purple-subtext subtext">
                            Update the colors to match the current branding when
                            necessary
                        </p>
                    </Row>

                    <Row className="comparison-row">
                        <Col className="one-half">
                            <img
                                src={require('../../images/branding-guidelines/original-illustration.png')}
                                alt=""
                            />
                            <p class="label">Original</p>
                        </Col>
                        <Col className="one-half right-blue-bubble">
                            <img
                                src={require('../../images/branding-guidelines/our-brand-illustration.png')}
                                alt=""
                            />
                            <p class="label">Our Brand</p>
                        </Col>
                    </Row>
                    <Row className="bubble-row">
                        <div class="bubble">
                            <p>
                                Collection can be found at
                                https://www.shutterstock.com/g/Anna+Toshcheva
                            </p>
                        </div>
                    </Row>
                </Container>
                <Container id="imagery" className="imagery-container">
                    <Row>
                        <Col className="left-blue-bubble">
                            <h2>Imagery</h2>
                            <p class="white-text">
                                A photo is worth a thousand words and we are
                                very selective when it comes to them. We DO NOT
                                use formal office environments but instead use a
                                more natural setting that represents more of who
                                we are, what we do, and our culture. We are
                                young creatives and want to showcase that.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="flexed">
                            <img
                                src={require('../../images/branding-guidelines/pod.jpg')}
                                alt=""
                            />
                            <img
                                src={require('../../images/branding-guidelines/office.jpg')}
                                alt=""
                            />
                            <img
                                src={require('../../images/branding-guidelines/employee.jpg')}
                                alt=""
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="flexed">
                            <img
                                src={require('../../images/branding-guidelines/client.jpg')}
                                alt=""
                            />
                            <img
                                src={require('../../images/branding-guidelines/collab.jpg')}
                                alt=""
                            />
                            <img
                                src={require('../../images/branding-guidelines/coffee.jpg')}
                                alt=""
                            />
                        </Col>
                    </Row>
                </Container>
                <Container id="icons" className="icons-container">
                    <Row>
                        <Col>
                            <h2>Icons</h2>
                            <p>
                                We use different styled icons throughout our
                                marketing, depending on the project. For us,
                                icons help enhance our ideas without using a
                                full image.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="one-half colors-column">
                            <h3>Two or more colors</h3>
                            <div class="flexed">
                                <img
                                    src={require('../../svgs/branding-guidelines/wrong.svg')}
                                    alt=""
                                />
                                <img
                                    src={require('../../svgs/branding-guidelines/right.svg')}
                                    alt=""
                                />
                                <img
                                    src={require('../../svgs/branding-guidelines/warning.svg')}
                                    alt=""
                                />
                            </div>
                        </Col>
                        <Col className="one-half gradient-column">
                            <h3>One gradient color</h3>
                            <img
                                src={require('../../svgs/branding-guidelines/one-gradient-icons.svg')}
                                alt=""
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="one-half glass-column">
                            <h3>Glass effect</h3>
                            <div class="flexed">
                                <img
                                    src={require('../../svgs/branding-guidelines/trending.svg')}
                                    alt=""
                                />
                                <img
                                    src={require('../../svgs/branding-guidelines/announcement.svg')}
                                    alt=""
                                />
                                <img
                                    src={require('../../svgs/branding-guidelines/target.svg')}
                                    alt=""
                                />
                            </div>
                        </Col>
                        <Col className="one-half outlined-column">
                            <h3>Outlined</h3>
                            <div>
                                <div class="flexed">
                                    <div class="image-container">
                                        <img
                                            src={require('../../svgs/branding-guidelines/wins.svg')}
                                            alt=""
                                        />
                                    </div>
                                    <div class="image-container">
                                        <img
                                            src={require('../../svgs/branding-guidelines/meetings.svg')}
                                            alt=""
                                        />
                                    </div>
                                    <div class="image-container">
                                        <img
                                            src={require('../../svgs/branding-guidelines/notes.svg')}
                                            alt=""
                                        />
                                    </div>
                                    <div class="image-container">
                                        <img
                                            src={require('../../svgs/branding-guidelines/call.svg')}
                                            alt=""
                                        />
                                    </div>
                                    <div class="image-container">
                                        <img
                                            src={require('../../svgs/branding-guidelines/unsubscribe.svg')}
                                            alt=""
                                        />
                                    </div>
                                    <div class="image-container">
                                        <img
                                            src={require('../../svgs/branding-guidelines/star.svg')}
                                            alt=""
                                        />
                                    </div>
                                    <div class="image-container">
                                        <img
                                            src={require('../../svgs/branding-guidelines/hot.svg')}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div class="flexed">
                                    <div class="image-container">
                                        <img
                                            src={require('../../svgs/branding-guidelines/stars.svg')}
                                            alt=""
                                        />
                                    </div>
                                    <div class="image-container">
                                        <img
                                            src={require('../../svgs/branding-guidelines/email.svg')}
                                            alt=""
                                        />
                                    </div>
                                    <div class="image-container">
                                        <img
                                            src={require('../../svgs/branding-guidelines/sms.svg')}
                                            alt=""
                                        />
                                    </div>
                                    <div class="image-container">
                                        <img
                                            src={require('../../svgs/branding-guidelines/search.svg')}
                                            alt=""
                                        />
                                    </div>
                                    <div class="image-container">
                                        <img
                                            src={require('../../svgs/branding-guidelines/higher.svg')}
                                            alt=""
                                        />
                                    </div>
                                    <div class="image-container">
                                        <img
                                            src={require('../../svgs/branding-guidelines/lower.svg')}
                                            alt=""
                                        />
                                    </div>
                                    <div class="image-container">
                                        <img
                                            src={require('../../svgs/branding-guidelines/xlsx.svg')}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className="data-graphs-container">
                    <Row>
                        <Col>
                            <h2>Data Graphs</h2>
                            <p>
                                These graphs will help with data info to
                                represent visually the info in different assets
                                fro GlassHive.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="flexed">
                            <div class="image-container">
                                <img
                                    src={require('../../svgs/branding-guidelines/circle.svg')}
                                    alt=""
                                />
                            </div>
                            <div class="image-container">
                                <img
                                    src={require('../../svgs/branding-guidelines/double-circle.svg')}
                                    alt=""
                                />
                            </div>
                            <div class="image-container">
                                <img
                                    src={require('../../svgs/branding-guidelines/horizontal.svg')}
                                    alt=""
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="flexed">
                            <div class="image-container">
                                <img
                                    src={require('../../svgs/branding-guidelines/vertical-graph.svg')}
                                    alt=""
                                />
                            </div>
                            <div class="image-container">
                                <img
                                    src={require('../../svgs/branding-guidelines/line-graph.svg')}
                                    alt=""
                                />
                            </div>
                            <div class="image-container">
                                <img
                                    src={require('../../svgs/branding-guidelines/subtraction.svg')}
                                    alt=""
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container id="in-actions" className="in-action-container">
                    <Row className="brands-row">
                        <Col className="two-thirds">
                            <h2>Brand In-Action</h2>
                            <p class="white-text">
                                Here’s how our brand looks on different
                                marketing materials. We like to keep things
                                simple and most importantly FUN!
                            </p>
                        </Col>
                        <Col className="one-third"></Col>
                    </Row>
                    <Row className="t-shirts-row">
                        <Col>
                            <h3>Fashionable T’s</h3>
                            <img
                                className="branding-shirts"
                                src={require('../../images/branding-guidelines/shirts.png')}
                                alt=""
                            />
                        </Col>
                    </Row>
                    <Row className="wow-box-row">
                        <Col>
                            <h3>Promotional “Wow” Boxes</h3>
                            <img
                                className="wow-boxes"
                                src={require('../../images/branding-guidelines/wow-box.png')}
                                alt=""
                            />
                        </Col>
                    </Row>
                    <Row className="coffee-cup-row">
                        <Col>
                            <h3>Colorful Coffee Cups</h3>
                            <img
                                className="coffee-cup"
                                src={require('../../images/branding-guidelines/cups.png')}
                                alt=""
                            />
                        </Col>
                    </Row>
                    <Row className="coin-row">
                        <Col>
                            <h3>Collectable Coin</h3>
                            <img
                                className="coin"
                                src={require('../../images/branding-guidelines/coin.png')}
                                alt=""
                            />
                        </Col>
                    </Row>
                    <Row className="card-row">
                        <Col>
                            <h3>Attractive Business Cards</h3>
                            <img
                                className="cards"
                                src={require('../../images/branding-guidelines/cards.png')}
                                alt=""
                            />
                        </Col>
                    </Row>
                    <Row className="brochure-row">
                        <Col>
                            <h3>Modern Brochure Style</h3>
                            <img
                                className="brochures"
                                src={require('../../images/branding-guidelines/brochure.png')}
                                alt=""
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};
export default IndexPage;
